import React from 'react'
import {graphql} from 'gatsby'

import Archive from '../../templates/Archive'

const CategoryPage = ({data, pageContext}) => {
  
  const articles = data.allContentfulResource.nodes

  return (
    <Archive articles = {articles} pageContext = {pageContext}/>
  )
}

export const query = graphql`
  query GetCategory($name: String){
    
    allContentfulResource( sort: { fields: date, order: DESC }, filter:{category:{elemMatch: {name: {eq: $name}}}}) {
      nodes {
        id
        title
        date(formatString: "DD MMMM YYYY")
        author
        category {
          name
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          file {
            url
          }
        }
        description {
          description
        }
      }
    }
  }
`;

export default CategoryPage