import React from "react";
import Featured from "../components/Featured";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { HomeIcon } from "@heroicons/react/solid";
import Newsletter from "../components/Newsletter";
import SEO from "../components/SEO";
import Pager from "../components/Pager";

const Archive = ({ articles, pageContext }) => {
  return (
    <Layout>
      <SEO title="Archive" slug="/articles" />
      {/* HERO */}
      <div className="hidden lg:block relative bg-e-blue overflow-hidden h-[350px]">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
            alt=""
          />
          {/* <div
            className="absolute inset-0 bg-e-blue mix-blend-multiply"
            aria-hidden="true"
          /> */}
          <svg
            width="925"
            height="366"
            viewBox="0 0 925 366"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-0"
          >
            <path
              d="M344.5 0.327271L0 365.327H925V0.327271H344.5Z"
              fill="#1D71B8"
              fillOpacity="0.4"
            />
          </svg>
        </div>
        <div className="relative max-w-7xl mx-auto pt-[310px] px-4 flex justify-end items-end">
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <Link
                    to="/"
                    className="text-white hover:text-e-blue transition"
                  >
                    <HomeIcon
                      className="flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to="/articles"
                    className="ml-4 text-xl font-medium text-white hover:text-e-blue transition"
                  >
                    Archive
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* LAYOUT WITH SIDEBAR */}
      <div className="bg-white">
        <div>
          <main className="container mx-auto px-4 my-12 sm:px-6 lg:px-8">
            <div className="py-12 flex flex-col flex-col-reverse lg:grid lg:grid-cols-4 lg:gap-x-12 xl:grid-cols-5">
              <div className=" lg:col-span-3 xl:col-span-4">
                <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                  {articles.map((article) => {
                    return (
                      <Link
                        key={article.id}
                        to={`/${article.title
                          .toLowerCase()
                          .replaceAll("!", "")
                          .replace(/[:.'(), ]/g, "-")
                          .replaceAll("--", "-")
                          .replaceAll("--", "-")
                          .replaceAll("?-", "-")}`}
                        className="p-4 md:w-1/3  mb-6 relative hover:bg-gray-100 transition"
                      >
                        <div>
                          <div className="-lg h-64 overflow-hidden hover:opacity-90 transition">
                            <GatsbyImage
                              alt="content"
                              className="object-cover object-center h-full w-full"
                              image={article.image.gatsbyImageData}
                            />
                          </div>
                        </div>
                        <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 mt-6">
                          {article.date}
                        </h2>
                        <h2 className="text-xl font-medium title-font text-gray-900 mt-5 h-20 overflow-hidden">
                          {article.title}
                        </h2>
                        <p className="text-base leading-relaxed mt-2 mb-3 h-20 overflow-hidden">
                          {`${article.description.description.slice(
                            0,
                            120
                          )}...`}
                        </p>
                        <svg
                          width="350"
                          height="44"
                          viewBox="0 0 350 44"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute"
                        >
                          <path
                            d="M1 1H350"
                            stroke="#1D71B8"
                            strokeLinecap="round"
                          />
                          <path
                            d="M134.902 43L163.728 0.999992"
                            stroke="#1D71B8"
                            strokeLinecap="round"
                          />
                        </svg>
                        <button className="text-e-blue inline-flex items-center mt-3 hover:underline cursor-pointer relative z-10">
                          Read More
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                          >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                          </svg>
                        </button>
                      </Link>
                    );
                  })}
                </div>
                <Pager pageContext={pageContext} />
              </div>
              <Sidebar />
            </div>
          </main>
        </div>
      </div>
      <Newsletter />
    </Layout>
  );
};

export default Archive;
